import { Feature } from './types';
import {
  allPlatformsDescription,
  allPlatformsTitle,
  autoSyncDescription,
  autoSyncTitle,
  cancelAnytimeDescription,
  cancelAnytimeTitle,
  cloudBackupDescription,
  cloudBackupTitle,
  dedicatedSupportDescription,
  dedicatedSupportTitle,
  multiAccountDescription,
  multiAccountTitle,
  rematchDescription,
  rematchTitle,
  songOrderDescription,
  songOrderTitle,
  unlimitedTransfersDescription,
  unlimitedTransfersTitle
} from './messages';

export const features: Feature[] = [
  {
    title: autoSyncTitle,
    description: autoSyncDescription,
    icon: 'sync-alt'
  },
  {
    title: dedicatedSupportTitle,
    description: dedicatedSupportDescription,
    icon: 'medkit'
  },
  {
    title: cloudBackupTitle,
    description: cloudBackupDescription,
    icon: 'cloud-upload-alt'
  },
  {
    title: cancelAnytimeTitle,
    description: cancelAnytimeDescription,
    icon: 'calendar-times'
  },
  {
    title: rematchTitle,
    description: rematchDescription,
    icon: 'shuffle'
  },
  {
    title: songOrderTitle,
    description: songOrderDescription,
    icon: 'memo'
  },
  {
    title: unlimitedTransfersTitle,
    description: unlimitedTransfersDescription,
    icon: 'user-music'
  },
  {
    title: allPlatformsTitle,
    description: allPlatformsDescription,
    icon: 'phone-laptop'
  },
  {
    title: multiAccountTitle,
    description: multiAccountDescription,
    icon: 'users'
  }
];

export const featuresColorsClassNames = ['text-gold', 'text-accent', 'text-primary'];
