import React from 'react';
import { useIntl } from 'react-intl';
import Link from 'next/link';
import { IndexHomeLink } from '../index/IndexHomeLink';
import { useScrollToHashPosition } from '../../../components/utils';

export const CommonLinksComponent: React.FC = React.memo(() => {
  const [servicesURL, onAvailableServicesClick] = useScrollToHashPosition('available-music-services', '/', '/');
  const intl = useIntl();
  return (
    <div className="home-links">
      <Link prefetch={false} href={servicesURL.href} as={servicesURL.as} passHref legacyBehavior>
        <IndexHomeLink
          onClick={onAvailableServicesClick}
          label={intl.formatMessage({
            id: 'web.home.all-supported-platforms',
            defaultMessage: 'List of all supported platforms'
          })}
        />
      </Link>
      <IndexHomeLink
        href="https://support.freeyourmusic.com/helpdesk/"
        label={intl.formatMessage({
          id: 'web.home.faq',
          defaultMessage: 'FAQ'
        })}
        nofollow
      />
      <IndexHomeLink
        href="mailto:support@freeyourmusic.com"
        label={intl.formatMessage({
          id: 'web.home.contact-us',
          defaultMessage: 'Contact us'
        })}
      />
      <IndexHomeLink
        href="mailto:feedback@freeyourmusic.com"
        label={intl.formatMessage({
          id: 'web.home.leave-feedback',
          defaultMessage: 'Missing something? Leave feedback'
        })}
      />
    </div>
  );
});
