import React from 'react';
import { useIntl } from 'react-intl';
import { Col } from 'reactstrap';
import Link from 'next/link';
import { FeatureBoxProps } from './props';

const FeatureBoxComponent: React.FC<FeatureBoxProps> = ({ feature, className }) => {
  const intl = useIntl();
  return (
    <Col xs={12} md={5} className="feature-box">
      <div className="d-flex">
        <div className="d-flex align-items-center">
          <i className={`fa-solid fa-${feature.icon} me-3 ${className} fa-2x`} />
        </div>
        <div>
          <div className="feature-title">{intl.formatMessage(feature.title)}</div>
          {feature.description && <span className="fw-light">{intl.formatMessage(feature.description)}</span>}
          {feature.link && (
            <span className="fw-light">
              <br />
              <Link prefetch={false} href={feature.link}>
                {intl.formatMessage({
                  defaultMessage: 'Learn more',
                  id: 'web.learn-more'
                })}
              </Link>
            </span>
          )}
        </div>
      </div>
    </Col>
  );
};

export const FeatureBox = React.memo(FeatureBoxComponent);
