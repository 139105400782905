import React, { useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { AnimatedWave } from '../components/AnimatedWave';

const fixedDownloads = 5123789;
const downloadsPerSecond = 3775 / 24 / 3600; // / 24h / 3600s
const downloadsStartsCountingAt = new Date('december,14,2023').getTime();
const tracksRatePerDownload = 2489;
const tracksCounterConstant = fixedDownloads * tracksRatePerDownload;

const tracksRatePerSecond = downloadsPerSecond * tracksRatePerDownload;
export const useDownloadCounter = (): [number, number] => {
  const [currentDownloads, setCurrentDownloads] = useState(fixedDownloads);
  const [currentTracks, setCurrentTracks] = useState(fixedDownloads);

  useEffect(() => {
    const calculateDownloads = () => {
      const downloadsSecondsSinceStart = (Date.now() - downloadsStartsCountingAt) / 1000;
      setCurrentDownloads(Math.floor(fixedDownloads + downloadsSecondsSinceStart * downloadsPerSecond));
    };
    calculateDownloads();
    const downloadsInterval = setInterval(calculateDownloads, Math.floor((1 / downloadsPerSecond) * 1000));

    const calculateTracks = () => {
      const tracksSeconds = (Date.now() - downloadsStartsCountingAt) / 1000;
      setCurrentTracks(Math.floor(tracksCounterConstant + tracksSeconds * tracksRatePerSecond));
    };
    calculateTracks();
    const tracksInterval = setInterval(calculateTracks, Math.floor(1 / tracksRatePerSecond));

    return () => {
      clearInterval(downloadsInterval);
      clearInterval(tracksInterval);
    };
  }, []);

  return [currentDownloads, currentTracks];
};

const IndexDownloadsInfoDownloadsCounterComponent: React.FC = React.memo(() => {
  const [downloadsCounter] = useDownloadCounter();
  const intl = useIntl();
  return <>{intl.formatNumber(downloadsCounter)}</>;
});

const IndexDownloadsInfoTracksCounterComponent: React.FC = React.memo(() => {
  const [, tracksCounter] = useDownloadCounter();
  const intl = useIntl();
  return <>{intl.formatNumber(tracksCounter)}</>;
});
export const IndexDownloadsInfo: React.FC = React.memo(() => {
  return (
    <AnimatedWave
      leftTitle={
        <>
          <i className="fa-light fa-arrow-square-down icon" />{' '}
          <FormattedMessage id="web.home.downloads-info.downloads" defaultMessage="Downloads" />
        </>
      }
      leftSubtitle={<IndexDownloadsInfoDownloadsCounterComponent />}
      rightTitle={
        <>
          <i className="fa-light fa-user-music icon" />{' '}
          <FormattedMessage id="web.home.downloads-info.tracks-moved" defaultMessage="Songs & albums moved" />
        </>
      }
      rightSubtitle={<IndexDownloadsInfoTracksCounterComponent />}
    />
  );
});
