import React from 'react';
import { Container, Row } from 'reactstrap';
import { FeatureBox } from './featureBox/FeatureBox';
import { Feature } from './types';
import { TypedMemo } from '../../../components/types';
import { featuresColorsClassNames } from './consts';

const FeaturesComponent: React.FC<
  React.PropsWithChildren<{
    title: string;
    features: Feature[];
  }>
> = ({ title, features, children }) => {
  return (
    <Container className="my-5 py-5" id="features">
      <p className="main-header text-center mb-3 mb-lg-5">{title}</p>
      {children}
      <Row className="justify-content-center mx-0">
        {features.map((feature, index) => {
          const iconColorClass = featuresColorsClassNames[index % featuresColorsClassNames.length];
          return <FeatureBox key={feature.title.id} feature={feature} className={iconColorClass} />;
        })}
      </Row>
    </Container>
  );
};

export const Features = TypedMemo(FeaturesComponent);
