import React, { MouseEventHandler, Suspense } from 'react';
import { nextAsset } from '../../../components/nextAsset';

export const IndexHomeLink: React.FC<{
  href?: string;
  label: string;
  nofollow?: boolean;
  onClick?: MouseEventHandler<HTMLAnchorElement>;
}> = React.forwardRef(({ href, onClick, label, nofollow }, ref) => {
  return (
    <a
      href={href}
      className="home-links-link"
      rel={nofollow === true ? 'nofollow' : undefined}
      onClick={onClick}
      ref={ref as any}
    >
      <span className="text">{label}</span>{' '}
      <span className="icon">
        <Suspense fallback={null}>
          <img src={nextAsset(`images/arrow-right-dark.svg`)} alt="Open" />
        </Suspense>
      </span>
    </a>
  );
});
