import React, { useRef, useState } from 'react';
import { Col, Container, Row } from 'reactstrap';
import classNames from 'classnames';
import { prefix } from 'inline-style-prefixer';
import { useScrollPosition } from '../../../components/hooks/useScrollPosition';

export interface AnimatedWaveProps {
  leftTitle?: React.ReactNode;
  leftSubtitle?: React.ReactNode;
  rightTitle?: React.ReactNode;
  rightSubtitle?: React.ReactNode;
}

const AnimatedWaveComponent: React.FC<
  {
    className?: string;
    firstClassName?: string;
    secondClassName?: string;
    tracksNumberClassNames?: string;
  } & AnimatedWaveProps
> = React.memo(
  ({
    className,
    firstClassName,
    secondClassName,
    tracksNumberClassNames,
    leftTitle,
    leftSubtitle,
    rightTitle,
    rightSubtitle
  }) => {
    const [currentStep, setCurrentStep] = useState<number>(0);
    const viewportRef = useRef<HTMLDivElement>(null);

    useScrollPosition((info) => {
      const ref = viewportRef.current;
      if (!ref) {
        return;
      }

      const dom = ref.getBoundingClientRect();
      const { height } = dom;

      setCurrentStep(
        Math.min(
          1,
          Math.max(
            0,
            ((-1 * info.y + document.body.offsetHeight - height) / (document.body.offsetHeight - height)) * 1.5
          )
        )
      );
    }, viewportRef);

    return (
      <div className={classNames('animated-wave', className)} ref={viewportRef}>
        <Container fluid>
          <div
            className="animated-wave-progress"
            style={prefix({
              width: '100%',
              clipPath: `inset(0 ${Math.ceil((1 - currentStep) * 100)}% 0 0)`
            })}
          />
          <Row>
            <Col
              className={classNames('animated-wave-text ps-5 pb-5', firstClassName, {
                'is-hidden': currentStep <= 0.15
              })}
            >
              <div className="animated-wave-text-title">{leftTitle}</div>
              <div className="animated-wave-text-number">{leftSubtitle}</div>
            </Col>
            <Col
              className={classNames('animated-wave-text text-end pe-5 pb-5', secondClassName, {
                'is-hidden': currentStep < 0.5
              })}
            >
              <div className="animated-wave-text-title">{rightTitle}</div>
              <div className={classNames('animated-wave-text-number', tracksNumberClassNames)}>{rightSubtitle}</div>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
);
export const AnimatedWave: React.FC<AnimatedWaveProps> = React.memo(
  ({ leftTitle, leftSubtitle, rightTitle, rightSubtitle }) => {
    return (
      <div className="py-5">
        <AnimatedWaveComponent
          secondClassName="d-lg-block d-none"
          leftTitle={leftTitle}
          leftSubtitle={leftSubtitle}
          rightTitle={rightTitle}
          rightSubtitle={rightSubtitle}
        />
        <AnimatedWaveComponent
          className="d-lg-none background-right"
          firstClassName="d-none"
          tracksNumberClassNames="pb-5"
          leftTitle={leftTitle}
          leftSubtitle={leftSubtitle}
          rightTitle={rightTitle}
          rightSubtitle={rightSubtitle}
        />
      </div>
    );
  }
);
