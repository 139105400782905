import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Col, Container, Row } from 'reactstrap';
import { CommonLinksComponent } from '../components/CommonLinksComponent';

export const IndexSupport: React.FC = React.memo(() => {
  return (
    <>
      <Container className="text-center my-5">
        <Row>
          <Col>
            <p className="smaller-header text-center mt-5">
              <FormattedMessage id="web.home.support.title" defaultMessage="Delightful support from real people" />
            </p>
            <p className="lead text-center mb-5">
              <FormattedMessage
                id="web.home.support.description"
                defaultMessage="FreeYourMusic is simple to use, but if you need help with setting up your transfers, our team is here to help."
              />
            </p>
          </Col>
        </Row>
        <Row>
          <Col xs={0} sm={1} md={2} />
          <Col xs={12} sm={10} md={8}>
            <CommonLinksComponent />
          </Col>
          <Col xs={0} sm={1} md={2} />
        </Row>
      </Container>
    </>
  );
});
